.justified-text {
  text-align: justify;
}

.solution-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
}

/* Ensures images are consistently sized and styled */
img {
  max-width: 100%;
  height: 250px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensures images cover the area without distortion */
  margin-bottom: 20px;
}

/* Optional: You can add media queries if you want responsive adjustments */
@media (max-width: 768px) {
  img {
    height: auto; /* Adjust height for smaller screens */
  }
}